import React from "react"
import { Link } from "gatsby"
import { Container, Button } from "@material-ui/core"

import SEO from "../components/theme/seo"
import Layout from "../components/theme/layout"
import Masthead from "../components/theme/masthead"
import OneCol from "../components/one-col"
import TwoCol from "../components/two-col"
import Parallax from "../components/parallax"

import Wrench from "../images/svg/rench.svg"
import Tire from "../images/svg/tire.svg"
import Detail from "../images/svg/water.svg"
import Rust from "../images/svg/rust-proof.svg"
import Safety from "../images/svg/safety.svg"

import ServicesBanner from "../images/services-banner.jpg"
import Service1 from "../images/service-1.jpg"
import Service2 from "../images/service-2.jpg"
import Service3 from "../images/service-3.jpg"
import Service4 from "../images/service-4.jpg"
import Service5 from "../images/service-5.jpg"
import BusCTA from "../images/contact-cta-banner.jpg"
import DetailingList from "../images/detailing-services-list.jpg"

const Services = () => (
  <Layout>
    <SEO title="Our Services" />
    <Masthead title="Our Services" img={ServicesBanner} />
    <Container>
      <OneCol
        intro={true}
        title="Our Services"
        description="Audi, BMW, Mercedes, Volkswagen, Jaguar and MINI may be our specialties, but we proudly service all makes and models with the same care and attention."
      />
      <hr />
    </Container>

    <Container className="twocol-container home" maxWidth="lg">
      <TwoCol
        order={true}
        services={true}
        img={Wrench}
        bgImg={Service1}
        newTab={"/labour-rates.pdf"}
        title="Repairs &amp; Maintenance"
        caption="JJ Automotive Insight is a full-service automotive centre. From tires and transmissions to brakes and tune-ups, it’s our commitment to ensure your car is running at its best."
      />
      <TwoCol
        order={false}
        services={true}
        img={Tire}
        bgImg={Service2}
        title="Tires &amp; Storage"
        caption="Wheels and tire packages are available throughout the year – we’ve got a great selection for all makes and models. Ask about our safe, indoor tire storage - at $60 per season, you can’t go wrong."
      />
      {/* pdf */}
      <TwoCol
        order={true}
        services={true}
        img={Detail}
        bgImg={Service3}
        newTab={"/detailing-rates.pdf"}
        title="Auto Detailing"
        caption="We offer expert automotive detailing on-site. Our experienced detailers are committed to your satisfaction. Get the professional car cleaning you and your vehicle deserve."
      />
      {/* pdf */}

      <TwoCol
        order={false}
        services={true}
        img={Rust}
        bgImg={Service4}
        title="Rust Proofing"
        caption="Our Canadian winters are extra hard on our cars and trucks. Undercoat rust proofing not only protects your vehicle, it helps it last longer while increasing its resale value."
      />
      <TwoCol
        order={true}
        services={true}
        img={Safety}
        bgImg={Service5}
        title="Motor Vehicle Safety Inspection Station"
        caption="We are licensed by the Ministry of Transportation to conduct motor vehicle safety inspections and certifications."
      />
    </Container>

    <Parallax
      img={BusCTA}
      imgPosition="right"
      title="Your vehicle is in good hands with us."
      link="/contact-us"
      linkTitle="Call Us Today"
    />
  </Layout>
)

export default Services
